import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import BannerImage from '../components/Page/BannerImage'
import FestivalVolunteer from '../components/Page/FestivalVolunteer'
import ContactForm from '../components/Form/ContactForm'

const volunteersPage = ({data}) => {
	return (
        <Layout>
			<BannerImage image={data.file.childImageSharp.gatsbyImageData} />
			<section className="flex flex-row items-start flex-wrap pt-12 pb-16 bg-boswell-handwriting">
				<div className="md:w-1/2">
					<FestivalVolunteer />
				</div>
				<div className="md:w-1/2 mx-auto">
					<ContactForm
						enquiryType={`volunteer`}
						namePlaceholderText={`A W Some`}
						emailPlaceholderText={`awsome@volunteer.org`}
						messagePlaceholderText={`Tell us a bit about how you could help`}
						submitButtonText={`Send enquiry`}
					/>
				</div>
			</section>
		</Layout>
    );
}

export const query = graphql`{
  file(relativePath: {eq: "banner/Boswell-Book-Festival-Volunteer.jpg"}) {
    childImageSharp {
      gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
    }
  }
}
`

export default volunteersPage
